import * as React from 'react'
import styled from '@emotion/styled'
require('../../node_modules/slick-carousel/slick/slick.css');
require('../../node_modules/slick-carousel/slick/slick-theme.css');
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import Swiper from 'react-id-swiper';


import Lightbox from 'lightbox-react';
import '../styles/lightbox.css';
import {Component} from "react";
import {Link} from "gatsby";
import {fonts} from "../styles/variables";
import { FormattedMessage } from 'react-intl';
import Loader from 'react-loader-spinner'
import
{
  HomeSliderImg1,
  HomeSliderImg10,
  HomeSliderImg2,
  HomeSliderImg3,
  HomeSliderImg4,
  HomeSliderImg5,
  HomeSliderImg6,
  HomeSliderImg7,
  HomeSliderImg8,
  HomeSliderImg9,
  ImgWrapper,
  LightboxImg1, LightboxImg10,
  LightboxImg2,
  LightboxImg3, LightboxImg4, LightboxImg5, LightboxImg6, LightboxImg7, LightboxImg8, LightboxImg9
} from './swipesliders/img/HomeSliderImg'
import LocalizedLink from './int/LocalizedLink'



const StyledSpinner = styled.div`
width:100%;
height:100%;
margin-top:15px;
margin-bottom:15px;
transform: rotate(180deg);
display:flex;
justify-content:center;
align-items:center;

`


/*
  const img_slider_1 = require('../../assets/images/V7_002_Kitchen.jpg');
const img_slider_2 = require('../../assets/images/V7_003_Reception.jpg');
const img_slider_3 = require('../../assets/images/V7_004_10.jpg');
const img_slider_4 = require('../../assets/images/V7_009_Wood.jpg');
const img_slider_5 = require('../../assets/images/V7_010_FrontLeft.jpg');
const img_slider_6 = require('../../assets/images/V7_011_Bike.jpg');
const img_slider_7 = require('../../assets/images/V7_013_Aesop_8190.jpg');
const img_slider_8 = require('../../assets/images/V7_015_Chairs.jpg');
const img_slider_9 = require('../../assets/images/V7_036_Buffalo.jpg');
const img_slider_10 = require('../../assets/images/V7_061_Jadore_Mural.jpg');
*/
const images = [
  <LightboxImg1/>,<LightboxImg2/>,<LightboxImg3/>,<LightboxImg4/>,<LightboxImg5/>,<LightboxImg6/>,<LightboxImg7/>,<LightboxImg8/>,<LightboxImg9/>,<LightboxImg10/>

];

const BookButton = styled.div`
cursor:pointer;
font-weight:700;
width:254px;
//margin-bottom:10px;
//margin-top:20px;
text-transform:uppercase;
height:48px;
 display: flex;
 align-items:center;
 justify-content:center;
  border-radius: 10rem;
  color: #fff;
  font-size: 1rem;
  //letter-spacing: .15rem;
  transition: all .3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  font-weight:bold;
  font-size:21px;
margin-left:auto;
margin-right:auto;
font-family:${fonts.button};
letter-spacing:2px;


:before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background-color: #188cec;
  transition: all .3s;
  border-radius: 10rem;
  z-index: -1;
}

 :after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #54aaf1;
  border-radius: 10rem;
  z-index: -2;
}

:hover {
  color: #fff;
}

:hover:before {
  width: 100%;
}


   @media screen and (max-width: 1295px) {
z-index:999999;
}


`

const A = styled.div`
display:flex;
justify-content:center;
align-items:center;
color:white;
`


const settings = {
  dots: false,
  arrows: false,
  autoplay: true,
  autoplaySpeed: 0,
  className: "center",
  centerMode: true,
  infinite: true,
  centerPadding: "60px",
  slidesToShow:2,
  speed: 11000,
  easing:'linear',
  slidesToScroll: 1

};
const StyledSlider = styled(Swiper)`
  display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
`

const Img = styled.img`
  max-width:auto !important;
@media screen and (max-width: 1295px)
    {
    width:100% !important;
    height:auto !important;
    max-height:100% !important;
    }


`

const SmallImgDiv = styled.div`
max-width:auto !important;
`






const StyledCarousel = styled(Carousel)`


    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
        background:rgba(0,0,0,.1) !important;

   height:auto !important;
li{
background:none !important;
}

ul .carousel .slider.animated{
transform: translate3d(0px, 0px, 0px) !important;
}

.carousel .slider.animated{
max-height:auto !important;


}

ul {
@media screen and (max-width: 1295px)
    {

    }
}





li:nth-child(1){
min-width: 55.2% !important;
opacity: 0;  }





li:nth-child(5){
min-width: 51.2% !important;  }

li:nth-child(6){
min-width: 51.2% !important;  }

li:nth-child(7){
min-width: 52.2% !important;  }


  @media screen and (max-width: 1000px)
    {
    display:none;
    }





}



  `

const StyledMobileCarousel = styled(Carousel)`
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display:none;
    background:rgba(68,68,68,.2) !important;



li{
background:none !important;
}
     @media screen and (max-width: 1000px)
    {
    display:flex;
    }




  `




interface HomeSliderProps {
  className?: string
}

const params = {
  speed:9000,
  watchSlidesVisibility: true,
  loop:true,
  loopedSlides:1.2,
  slidesPerView:1.2,
  spaceBetween: 0,
  autoplay: {
    delay: 1000,
  },


};

const ImgDiv = styled.div`
.gatsby-image-wrapper{

}
img{
}
`

const LightboxWrapper = styled.div`
  .gatsby-image-wrapper-constrained{
    position: absolute !important;
    left: 50% !important;
    top: 50% !important;
    transform: translate(-50%,-50%) !important;
    width: 100% !important;
    max-width: 1013px !important;
  }

`

const StyledLightbox:any = styled(Lightbox)`
z-index:9999999 !important;






`

const StyledLink = styled(LocalizedLink)`
opacity:1 !important;


`

const SilentSRL = styled.div`
display:none;
`


const customStyles = {
  overlay : {
    zIndex                   : 9999999999,

  },

};



export default class HomeSlider extends Component<any,any> {
  constructor(props) {
    super(props);

    this.state = {
      photoIndex: 0,
      isOpen: false,
      isRendered:false
    };
  }

  componentDidMount(){
    this.setState({
      isRendered:true
    })

  }
  render() {
    const { photoIndex, isOpen } = this.state;

    return (
      <div>

        <div>
          <StyledCarousel  autoPlay infiniteLoop dynamicHeight centerMode centerSlidePercentage={72}  showArrows={true} showIndicators={false}  showStatus={false} showThumbs={false}  interval={2500} transitionTime={1000} >
            <ImgDiv onClick={() => this.setState({ isOpen: true, photoIndex:0 })}>
              <HomeSliderImg1/>
            </ImgDiv>
            <ImgDiv onClick={() => this.setState({ isOpen: true, photoIndex:1 })}>
              <HomeSliderImg2/>
            </ImgDiv>

            <ImgDiv onClick={() => this.setState({ isOpen: true, photoIndex:2 })}>
              <HomeSliderImg3/>
            </ImgDiv>

            <SmallImgDiv onClick={() => this.setState({ isOpen: true, photoIndex:3 })}>
              <HomeSliderImg4/>
            </SmallImgDiv>

            <ImgDiv onClick={() => this.setState({ isOpen: true, photoIndex:4 })}>
              <HomeSliderImg5/>

            </ImgDiv>


            <ImgDiv onClick={() => this.setState({ isOpen: true, photoIndex:5 })}>
              <HomeSliderImg6/>
            </ImgDiv>

            <ImgDiv onClick={() => this.setState({ isOpen: true, photoIndex:6 })}>
              <HomeSliderImg7/>
            </ImgDiv>
            <ImgDiv  onClick={() => this.setState({ isOpen: true, photoIndex:7 })}>
              <HomeSliderImg8/>

            </ImgDiv>

            <ImgDiv  onClick={() => this.setState({ isOpen: true, photoIndex:8 })}>
              <HomeSliderImg9/>

            </ImgDiv>

            <ImgDiv  onClick={() => this.setState({ isOpen: true, photoIndex:9 })}>
              <HomeSliderImg10/>
            </ImgDiv>

          </StyledCarousel>

          <StyledMobileCarousel  infiniteLoop  dynamicHeight  showArrows={true} showIndicators={false}  showStatus={false} showThumbs={false}  interval={2500} transitionTime={1000} >
            <ImgDiv onClick={() => this.setState({ isOpen: true, photoIndex:0 })}>
              <HomeSliderImg1/>
            </ImgDiv>
            <ImgDiv onClick={() => this.setState({ isOpen: true, photoIndex:1 })}>
              <HomeSliderImg2/>
            </ImgDiv>

            <ImgDiv onClick={() => this.setState({ isOpen: true, photoIndex:2 })}>
              <HomeSliderImg3/>
            </ImgDiv>


            <ImgDiv onClick={() => this.setState({ isOpen: true, photoIndex:6 })}>
              <HomeSliderImg7/>
            </ImgDiv>
            <ImgDiv  onClick={() => this.setState({ isOpen: true, photoIndex:7 })}>
              <HomeSliderImg8/>
            </ImgDiv>

            <ImgDiv  onClick={() => this.setState({ isOpen: true, photoIndex:8 })}>
              <HomeSliderImg9/>
            </ImgDiv>

            <ImgDiv  onClick={() => this.setState({ isOpen: true, photoIndex:9 })}>
              <HomeSliderImg10/>
            </ImgDiv>

          </StyledMobileCarousel>



          {isOpen && (
            <LightboxWrapper>
            <StyledLightbox
              enableZoom={false}
              reactModalStyle={customStyles}
              imageCaption={<StyledLink to={'/buchen'}> <BookButton> <A><FormattedMessage id="btn_book" /></A></BookButton></StyledLink>}
              mainSrc={images[this.state.photoIndex]}
              nextSrc={images[(this.state.photoIndex + 1) % images.length]}
              prevSrc={images[(this.state.photoIndex + images.length - 1) % images.length]}
              onCloseRequest={() => this.setState({ isOpen: false })}
              onMovePrevRequest={() =>
                this.setState({
                  photoIndex: (this.state.photoIndex + images.length - 1) % images.length,
                })
              }
              onMoveNextRequest={() =>
                this.setState({
                  photoIndex: (this.state.photoIndex + 1) % images.length,
                })
              }
            />
            </LightboxWrapper>
          )}
        </div>
      </div>

    );
  }
}

