import * as React from 'react'
import styled from '@emotion/styled'
import { Link } from 'gatsby'
import Popup from 'reactjs-popup'
const nav_id = 'nav';
var Scroll = require('react-scroll');
var Element = Scroll.Element;
var scroller = Scroll.scroller;
import { FormattedMessage } from 'react-intl';
import LocalizedLink from "./int/LocalizedLink";
import LocalizedAnchorLink from './int/LocalizedAnchorLink'


const StyledNavigation = styled.div`
  height:40px;
  width: 80%;
  margin-left:auto;
  margin-right:auto;
  margin-top:3%;
  border-top: 2px solid rgba(68, 68, 68, 0.5);
  border-bottom: 1px solid rgba(68, 68, 68, 0.3);
  // box-shadow: 0 3px 2px -2px rgba(68,68,68,0.5);
  position:relative;
          z-index: 9999;
  @media screen and (max-width: 1295px) {

display:none;
   }





`

const Dropdown = styled.ul`
  background-color: #fff;
  margin-left:15px;
 text-transform: uppercase;
           opacity:1;
  font-size:0.9em;
margin-top:10px;
    cursor: pointer;
transition: 2s linear;
  text-align:left !important;
        list-style-type: none;
height:150px;
}


`



const NavInner = styled.div`
display: flex;
flex-direction: row;
align-items:center;
justify-content: space-between;
width:90%;
margin:auto;
padding-top:0.5%;
  .popup-content{
   width:210px !important;

   }


`

const StyledLink = styled(LocalizedLink)`
font-family: "Theano Didot", Regular;
letter-spacing: 4px;
font-size:18.4px;
color:rgb(68, 68, 68);
position:relative;
z-index:9999;
:hover{
color:black}
`

const StyledAnchorLink = styled(LocalizedAnchorLink)`
font-family: "Theano Didot", Regular;
letter-spacing: 4px;
font-size:1.15em;
color:rgb(68, 68, 68);
transition:all ease .7s;
:hover{
color:black;


}
`


const StyledLink_2 = styled.a`
font-family: "Theano Didot", Regular;
letter-spacing: 4px;
font-size:18.4px;
color:rgb(68, 68, 68);
position:relative;
z-index:9999;
:hover{
color:black}
`

const StyledDropLink = styled(Link)`
font-family: "Theano Didot", Regular;
letter-spacing: 4px;
font-size:1.15em;
color:rgb(68, 68, 68);
position:relative;
z-index:9999;
height:100%;
  display: inline-block;
  :hover ${Dropdown} {
        visibility: visible;
  transition-delay: 0s;
  transition: 2s ease-out;
                }


        :hover{
color:black;
}
`




class Navigation extends React.Component {

  constructor(props) {
    super(props);

  }

  handleStudioPlusClick(){
    scroller.scrollTo('studioPlusArea', {
      duration: 1500,
      smooth: true,
    })
  }

  handleSuiteClick(){
    scroller.scrollTo('suiteArea', {
      duration: 1500,
      smooth: true,
    })
  }

  handleStudioClick(){
    scroller.scrollTo('studioArea', {
      duration: 1500,
      smooth: true,
    })
  }

  render() {
    return (
      <StyledNavigation id={nav_id}>

        <NavInner>
          <StyledLink to="/"><FormattedMessage id="home_nav_1" /></StyledLink>
          <Popup
            trigger={<StyledLink to="/interior" className="button"> <FormattedMessage id="home_nav_2" /> </StyledLink>}
            position="bottom center"
            on="hover"
          >
            <Dropdown>
              <StyledLink  to="/interior"> <li> Interior </li></StyledLink>
              <StyledAnchorLink  to="/wohnen#studio"> <li> Studio </li></StyledAnchorLink>
              <StyledAnchorLink to="/wohnen#studio-plus"> <li> Studio Plus </li></StyledAnchorLink>
              <StyledAnchorLink to="/wohnen#suite"><li>      Suite </li></StyledAnchorLink>
            </Dropdown>
          </Popup>


          <StyledLink to="/buchen"><FormattedMessage id="home_nav_3" /></StyledLink>
                    <StyledLink_2 href="https://vilhelm7coworking.de/">COWORKING</StyledLink_2>
          <StyledLink to="/breakfast"><FormattedMessage id="home_nav_4" /></StyledLink>
          <StyledLink to="/lage"><FormattedMessage id="home_nav_5" /></StyledLink>
          <StyledLink to="/extras"><FormattedMessage id="home_nav_6" /></StyledLink>
        </NavInner>
        <Element name="myScrollToElement"></Element>
      </StyledNavigation>


  )

  }



}



export default Navigation;
