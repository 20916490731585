import React from "react"
import { StaticImage } from "gatsby-plugin-image"
const StyledLink = styled(LocalizedLink)`
opacity:1 !important;


`

//import { useLightbox } from 'simple-react-lightbox'
import styled from '@emotion/styled'
import LocalizedLink from '../int/LocalizedLink'
import { MdPictureAsPdf } from 'react-icons/md'


// USE THE IMPORT BELOW INSTEAD IF YOU ARE USING THE PRO VERSION
// import { useLightbox } from 'simple-react-lightbox-pro'

const imgPaths = [
 "../../../../content/booking/studio/V7_Studio_001.jpg",
]





const Div = styled.div`
width:30%;
height:100%;
  @media screen and (max-width: 1295px) {
width:100%;

   }

`

const DesktopThumb = styled.div`


.gatsby-image-wrapper-constrained {
width:66px;
}

 @media screen and (max-width: 666px) {


}

}

`

const ImgThumb = styled.div`
transition:.7s all ease;
display:block;
opacity:0.7;

.gatsby-image-wrapper-constrained {
width:7em;


}

 @media screen and (max-width: 666px) {


}

}

`
const ImgGoldThumb = styled.div`
display:none;
opacity:0;
transition:.7s all ease;

.gatsby-image-wrapper-constrained {
width:7em;


}

 @media screen and (max-width: 666px) {


}

}

`

const StyledPdfIcon = styled(MdPictureAsPdf)`
position:absolute;
color:rgba(49,54,57,.5);
transition:.7s all ease;

right:0;
top:0;
width:24px;
height:24px
`



const A: any = styled.a`
position:relative;
letter-spacing:1px !important;
margin-top:0px !important;
width:250px;
height:360px;
display:flex;
align-items:flex-start;
justify-content:center;
transition:.7s all ease;
background:rgba(49,54,57,.03);
border: 1px solid rgba(49,54,57,.04);
padding-bottom:40px;
padding-top:55px;
cursor:pointer;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: all 0.7s cubic-bezier(.25,.8,.25,1);
}

border-radius:7px;

:hover{
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
background:rgb(49,54,57);

${ImgThumb} {
display:none;
opacity:0;
}

${ImgGoldThumb} {
display:block;
opacity:1;
}


${StyledPdfIcon} {
color:rgba(212,175,55,1);
}
}




//opacity:0.4;


  @media screen and (max-width: 1295px) {
display:flex;
width:100%;
   }

`





export function V7ArtGoldThumb() {

  return (
    <ImgGoldThumb>
      <V7ArtGoldImg/>
    </ImgGoldThumb>
  )
}



export function V7ArtInResidencesPdf() {

  return (

      <A href={'https://v7-static-files.s3.eu-central-1.amazonaws.com/pdf/v7_art_in_residences_catalog_works.pdf'} target="_blank">
        <StyledPdfIcon/>
        <V7ArtThumb /><V7ArtGoldThumb />
      </A>
  )
}

export function V7ArtThumb() {

  return (
    <ImgThumb>
      <V7ArtImg/>
    </ImgThumb>
  )
}


export function V7ArtImg() {
  return (
    <div>
      <StaticImage
        src="../../content/assets/images/V7_Logo_art_in_residences.png"
        alt={"Vilhelm7 Art in Residences Logo"}
        placeholder="blurred"
        layout="constrained"
        quality="100"


      />

    </div>
  )
}

export function V7ArtGoldImg() {
  return (
    <div>
      <StaticImage
        src="../../content/assets/images/V7_Logo_art_in_residences_gold.png"
        alt={"Vilhelm7 Art in Residences Logo Gold"}
        placeholder="blurred"
        layout="constrained"
        quality="100"


      />

    </div>
  )
}



