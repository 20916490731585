import * as React from 'react'
import styled from '@emotion/styled'
import { FormattedMessage } from 'react-intl';


const StyledMidDiv = styled.div`

  width: 80%;
display: flex;
letter-spacing:1px !important;

margin-left:auto;
margin-right:auto;
height:265px;
align-items:flex-start;
justify-content: space-between;
font-family: "Theano Didot", Regular;
color:rgb(68, 68, 68);
padding-top:1%;

  @media screen and (max-width: 1295px) {
display:block;
height:auto;
   }


`;

const StyledP = styled.p`
font-family: "Theano Didot", Regular;
font-style: italic;
font-size: 200%;
text-align: right;
line-height: 45px;
letter-spacing: 2px;
color:rgb(68, 68, 68);
width:25%;
height:100%;

  @media screen and (max-width: 1295px) {
height:auto;
text-align:center;
margin-left:auto;
margin-right:auto;
width:100%;
margin-top:20px;
   }



`;

const Einzug = styled.p`
font-family: "Theano Didot", Regular;
font-size: 1.22em;
line-height: 1.5em;
color:rgb(68, 68, 68);
text-indent: 20px;
color:rgb(68, 68, 68);
height:100%;

  @media screen and (max-width: 1295px) {
display:none;
   }
`;

const P = styled.p`
font-family: "Theano Didot", Regular;
font-size: 1.22em;
line-height: 1.5em;
color:rgb(68, 68, 68);
color:rgb(68, 68, 68);
height:100%;

  @media screen and (max-width: 1295px) {
display:none;
   }
`;

const Desktop = styled.div`
width:30%;
  @media screen and (max-width: 1295px) {
display:none;
   }
`

const PBold = styled.p`
font-family: "Theano Didot", Regular;
font-size: 1.22em;
font-weight:bold;
line-height: 1.5em;
color:rgb(68, 68, 68);

color:rgb(68, 68, 68);

  @media screen and (max-width: 1295px) {
display:none;
   }
`;

const PItalic = styled.p`
font-family: "Theano Didot", Regular;
font-size: 1.22em;
font-style:italic;
line-height: 1.5em;
color:rgb(68, 68, 68);
width:100%;
color:rgb(68, 68, 68);
height:100%;
text-indent: 20px;
margin-bottom:3%;

  @media screen and (max-width: 1295px) {
display:none;
   }

`;

const Div = styled.div`
width:30%;
top:0;
height:100%;

  @media screen and (max-width: 1295px) {
display:none;
   }
`
const PQuote = styled.p`
font-family: "Theano Didot", Regular;
font-size: 0.9em;
line-height: 1.5em;
color:rgba(68, 68, 68,.5);
height:100%;
margin-bottom:0;
position:relative;
text-align:center;

  @media screen and (max-width: 1295px) {
height:auto;
   }

`;





export class MidDiv extends React.Component {

  constructor(props){
    super(props)
    this.state= {
      renderDone:false
    }
  }

  componentDidMount(){
    this.setState({renderDone:true})
  }

  render () {
    return (
  <StyledMidDiv>

<StyledP>  <FormattedMessage id="home_header_4" />
  <br/>   <FormattedMessage id="home_header_5" />
  <br/>   <FormattedMessage id="home_header_6" />
</StyledP>
    <Div>

     <PBold><FormattedMessage id="home_text_4" /></PBold>
    <Einzug><FormattedMessage id="home_text_5" />  </Einzug>
</Div>
<Desktop>
    <PItalic><FormattedMessage id="home_text_9" /> </PItalic>
    <PQuote>Friedrich II. von Preußen</PQuote> <br/>
  <P><FormattedMessage id="home_text_10" />  </P>

</Desktop>
  </StyledMidDiv>

    )
  }
}
