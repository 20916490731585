import * as React from 'react'
import styled from '@emotion/styled'
import { FormattedMessage } from 'react-intl';
//import {MdPictureAsPdf} from "react-icons/md";
//import {FaRegEye} from "react-icons/fa";
import Loader from 'react-loader-spinner'
import { V7ArtInResidencesPdf } from './IndexImg'




const StyledBottomDiv = styled.div`
letter-spacing:1px !important;
  width: 80%;
display: flex;
height:30%;
margin-left:auto;
margin-right:auto;
justify-content: space-between;
font-family: "Theano Didot", Regular;
color:rgb(68, 68, 68);
padding-top:4%;

  @media screen and (min-width: 1472px) {
padding-top:6%;
   }
  @media screen and (max-width: 1471px) {
padding-top:9%;
   }
  @media screen and (max-width: 1295px) {
display:block;
height:auto;
padding-top:6%;

   }


`;

const StyledBottomInner = styled.div`
letter-spacing:1px !important;
  width: 100%;
display: flex;
height:100%%;

justify-content: space-between;
font-family: "Theano Didot", Regular;
color:rgb(68, 68, 68);

  @media screen and (max-width: 1295px) {
display:block;
height:auto;
   }


`;

const StyledP = styled.p`
font-family: "Theano Didot", Regular;
font-style: italic;
font-size: 200%;
text-align: right;
line-height: 40px;
letter-spacing: 2px;
color:rgb(68, 68, 68);
width:25%;
height:auto;
position:relative;

  @media screen and (max-width: 1295px) {
height:auto !important;
width:100%;
margin:auto;
text-align:center;
   }



`;



const P = styled.p`
font-family: "Theano Didot", Regular;
font-size: 1.2em;
line-height: 1.5em;
color:rgb(68, 68, 68);
color:rgb(68, 68, 68);
height:100%;
position:relative;

  @media screen and (max-width: 1295px) {
height:auto;
   }

`;
const PCenter = styled.p`
font-family: "Theano Didot", Regular;
font-size: 1.2em;
line-height: 1.5em;
color:rgb(68, 68, 68);
color:rgb(68, 68, 68);
height:100%;
position:relative;
text-align:center;

  @media screen and (max-width: 1295px) {
height:auto;
   }

`;
const PQuote = styled.p`
font-family: "Theano Didot", Regular;
font-size: 0.9em;
line-height: 1.5em;
color:rgba(68, 68, 68,.5);
height:100%;
margin-bottom:0;
position:relative;
text-align:center;

  @media screen and (max-width: 1295px) {
height:auto;
   }

`;

const Einzug = styled.p`
font-family: "Theano Didot", Regular;
font-size: 1.2em;
line-height: 1.5em;
color:rgb(68, 68, 68);
text-indent: 20px;
color:rgb(68, 68, 68);


`;


const Div = styled.div`
width:30%;
height:100%;
  @media screen and (max-width: 1295px) {
width:100%;

   }

`

const DivMobile = styled.div`
width:100%;
height:auto;
margin:auto;
display:none;
  @media screen and (max-width: 1295px) {
display:block;
   }

`



const PBold = styled.p`
font-family: "Theano Didot", Regular;
font-size: 1.22em;
font-weight:bold;
line-height: 1.5em;
color:rgb(68, 68, 68);
width:100%;
margin-left:auto;
margin-right:auto;
margin-bottom:11px;
color:rgb(68, 68, 68);
text-align:center;


`;

const PItalic = styled.p`
font-family: "Theano Didot", Regular;
font-size: 1.22em;
font-style:italic;
line-height: 1.5em;
color:rgb(68, 68, 68);
width:100%;
color:rgb(68, 68, 68);
height:auto;
margin-top:1.4%;
margin-bottom:1.2%;
text-indent: 20px;



`;

const DeskDiv = styled.div`
display:flex;
justify-content:space-between;
align-items:flex-start;
  @media screen and (max-width: 1295px) {
display:none;
   }
`



const StyledSpinner = styled.div`
width:100%;
height:100%;
margin-top:15px;
margin-bottom:15px;
transform: rotate(180deg);
display:flex;
justify-content:center;
align-items:center;
-moz-transform: scale(1, -1);
-webkit-transform: scale(1, -1);
-o-transform: scale(1, -1);
-ms-transform: scale(1, -1);
transform: scale(1, -1);

`




export class BottomDiv extends React.Component {

constructor(props){
super(props)
this.state= {
  renderDone:false
}
}

componentDidMount(){
  this.setState({renderDone:true})
}

render () {
  return (
  <StyledBottomDiv>
    <StyledSpinner style={{display: (this.state.renderDone===true) ? 'none':'flex'}}>
      <Loader
        type="Triangle"
        color="rgb(68,68,68)"
        height={75}
        width={75}
        // timeout={30000} //3 secs

      />
    </StyledSpinner>
    <StyledBottomInner style={{display: (this.state.renderDone===true)? 'flex': 'none'}}>

    <DivMobile>
      <Einzug><FormattedMessage id="home_text_1" />{' '}
        <FormattedMessage id="home_text_2" /> <br/>
        <FormattedMessage id="home_text_3" />
      </Einzug>

        <PBold><FormattedMessage id="home_text_4" /></PBold>
        <Einzug><FormattedMessage id="home_text_5" />  </Einzug>

      <P><FormattedMessage id="home_text_6" />  "<FormattedMessage id="home_text_7" />"  <FormattedMessage id="home_text_8" /> </P>

      <PItalic><FormattedMessage id="home_text_9" />   </PItalic>
      <PQuote>Friedrich II. von Preußen</PQuote> <br/>
      <PCenter><FormattedMessage id="home_text_10" />   </PCenter>

      <Einzug><FormattedMessage id="home_text_11" />
        <br/> <FormattedMessage id="home_text_12" />  "<FormattedMessage id="home_text_13" />" <FormattedMessage id="home_text_14" />
      </Einzug>

<br/>
    </DivMobile>


      <DeskDiv>
<StyledP>Art in Residences
<br/>
<br/>
<br/>
<br/>
  Find Vilhelm7 on <a href='https://www.cremeguides.com/de/berlin/artikel/vilhelm7-hotel-kreuzberg/' target="_blank">creme guides</a> <br/>
</StyledP>
<Div>
<V7ArtInResidencesPdf/>
</Div>

    <Div>
    <Einzug><FormattedMessage id="home_text_11" />
    <br/> <FormattedMessage id="home_text_12" />  "<FormattedMessage id="home_text_13" />"  <FormattedMessage id="home_text_14" />
    </Einzug>


    </Div>
      </DeskDiv>



</StyledBottomInner>
    <DivMobile>
      <div>
      </div>
      <StyledP>        Art in Residences
      </StyledP>
      <br/>
      <br/>
      <StyledP >
        <div >
       <V7ArtInResidencesPdf/>

        </div>
        <br/>
        <br/>
        Find Vilhelm7 on <a target="_blank" href='https://www.cremeguides.com/de/berlin/artikel/vilhelm7-hotel-kreuzberg/'>creme guides</a> <br/>
      </StyledP>




    </DivMobile>
  </StyledBottomDiv>

)
}
}
