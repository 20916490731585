import React from "react"
import { useStaticQuery, graphql, Link } from 'gatsby'
import Img from "gatsby-image"
import { FormattedMessage } from 'react-intl';

import { StaticImage } from "gatsby-plugin-image"
const StyledLink = styled(LocalizedLink)`
opacity:1 !important;


`

import { useLightbox } from 'simple-react-lightbox'
import styled from '@emotion/styled'
import LocalizedLink from '../../int/LocalizedLink'
import { fonts } from '../../../styles/variables'
// USE THE IMPORT BELOW INSTEAD IF YOU ARE USING THE PRO VERSION
// import { useLightbox } from 'simple-react-lightbox-pro'

const imgPaths = [
  "../../../content/V7_002_Kitchen.jpg", "../../../content/V7_003_Reception.jpg",
  "../../../content/V7_004_10.jpg","../../../content/V7_009_Wood.jpg","../../../content/V7_010_FrontLeft.jpg",
  "../../../content/V7_013_Aesop_8190.jpg","../../../content/V7_061_Jadore_Mural.jpg",
  "../../../content/V7_036_Buffalo.jpg","../../../content/V7_015_Chairs.jpg"
]


const BookButton = styled.div`
cursor:pointer;
font-weight:700;
width:254px;
//margin-bottom:10px;
//margin-top:20px;
text-transform:uppercase;
height:48px;
 display: flex;
 align-items:center;
 justify-content:center;
  border-radius: 10rem;
  color: #fff;
  font-size: 1rem;
  //letter-spacing: .15rem;
  transition: all .3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  font-weight:bold;
  font-size:21px;
margin-left:auto;
margin-right:auto;
font-family:${fonts.button};
letter-spacing:2px;


:before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background-color: #188cec;
  transition: all .3s;
  border-radius: 10rem;
  z-index: -1;
}

 :after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #54aaf1;
  border-radius: 10rem;
  z-index: -2;
}

:hover {
  color: #fff;
}

:hover:before {
  width: 100%;
}


   @media screen and (max-width: 1295px) {
z-index:999999;
}


`

const A = styled.div`
display:flex;
justify-content:center;
align-items:center;
color:white;
`


const Div = styled.div`
`

export function ImgWrapper() {
  const { openLightbox, closeLightbox } = useLightbox()

  return (
    <Div onClick={()=> openLightbox(0)}>
<LightboxImg1/>
    </Div>
  )
}

export function LightboxImg1() {
  return (
    <div>
    <StaticImage
      src="../../../content/V7_002_Kitchen.jpg"
      alt={""}
      placeholder="blurred"
      layout="constrained"
      quality="100"


    />

    </div>
  )
}


export function LightboxImg2() {
  return (
    <StaticImage
      src="../../../content/V7_003_Reception.jpg"
      alt=""
      placeholder="blurred"
      layout="constrained"
      quality="100"


    />
  )
}

export function LightboxImg3() {
  return (
    <StaticImage
      src="../../../content/V7_004_10.jpg"
      alt=""
      placeholder="blurred"
      layout="constrained"
      quality="100"


    />
  )
}

export function LightboxImg4() {
  return (
    <StaticImage
      src="../../../content/V7_009_Wood.jpg"
      alt=""
      placeholder="blurred"
      layout="constrained"
      width="720"
      quality="100"


    />
  )
}

export function LightboxImg5() {
  return (
    <StaticImage
      src="../../../content/V7_010_FrontLeft.jpg"
      alt=""
      placeholder="blurred"
      layout="constrained"
      width="720"
      quality="100"



    />
  )
}

export function LightboxImg6() {
  return (
    <StaticImage
      src="../../../content/V7_011_Bike.jpg"
      alt=""
      placeholder="blurred"
      layout="constrained"
      width="734"
      quality="100"


    />
  )
}

export function LightboxImg7() {
  return (
    <StaticImage
      src="../../../content/V7_013_Aesop_8190.jpg"
      alt=""
      placeholder="blurred"
      layout="constrained"

    />
  )
}

export function LightboxImg8() {
  return (
    <StaticImage
      src="../../../content/V7_061_Jadore_Mural.jpg"
      alt=""
      placeholder="blurred"
      layout="constrained"

    />
  )
}

export function LightboxImg9() {
  return (
    <StaticImage
      src="../../../content/V7_036_Buffalo.jpg"
      alt=""
      placeholder="blurred"
      layout="constrained"

    />
  )
}

export function LightboxImg10() {
  return (
    <StaticImage
      src="../../../content/V7_015_Chairs.jpg"
      alt=""
      layout="constrained"
      placeholder="blurred"


    />
  )
}
export function HomeSliderImg1() {
  return (
    <StaticImage
      src="../../../content/V7_002_Kitchen.jpg"
      alt="beer"
      placeholder="blurred"
      layout="fullWidth"
      quality="100"


    />
  )
}

export function HomeSliderImg2() {
  return (
    <StaticImage
      src="../../../content/V7_003_Reception.jpg"
      alt="V7 Recption"
      placeholder="blurred"
          layout="fullWidth"
      quality="100"


    />
  )
}
export function HomeSliderImg3() {
  return (
    <StaticImage
      src="../../../content/V7_004_10.jpg"
      alt="V7 Suite Nr. 10"
      placeholder="blurred"
          layout="fullWidth"
      quality="100"


    />
  )
}
export function HomeSliderImg4() {
  return (
    <StaticImage
      src="../../../content/V7_009_Wood.jpg"
      alt="V7 Wooden Entrance"
      placeholder="blurred"
          layout="fullWidth"
      quality="100"


    />
  )
}
export function HomeSliderImg5() {
  return (
    <StaticImage
      src="../../../content/V7_010_FrontLeft.jpg"
      alt="V7 Entrance"
      placeholder="blurred"
          layout="fullWidth"
      quality="100"


    />
  )
}
export function HomeSliderImg6() {
  return (
    <StaticImage
      src="../../../content/V7_011_Bike.jpg"
      alt="V7 Bike"
      placeholder="blurred"
          layout="fullWidth"
      quality="100"


    />
  )
}
export function HomeSliderImg7() {
  return (
    <StaticImage
      src="../../../content/V7_013_Aesop_8190.jpg"
      alt="V7 Aesop"
      placeholder="blurred"
          layout="fullWidth"
      quality="100"


    />
  )
}
export function HomeSliderImg8() {
  return (
    <StaticImage
      src="../../../content/V7_061_Jadore_Mural.jpg"
      alt="Jadore Mural"
      placeholder="blurred"
          layout="fullWidth"
      quality="100"


    />
  )
}
export function HomeSliderImg9() {
  return (
    <StaticImage
      src="../../../content/V7_036_Buffalo.jpg"
      alt="V7 Buffalo"
      placeholder="blurred"
          layout="fullWidth"
      quality="100"


    />
  )
}
export function HomeSliderImg10() {
  return (
    <StaticImage
      src="../../../content/V7_015_Chairs.jpg"
      alt="V7 Chairs"
      placeholder="blurred"
          layout="fullWidth"
      quality="100"

      tranformOptions=
        {{fit: "cover", cropFocus: "attention"}}
    />
  )
}
