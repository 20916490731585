import * as React from 'react'
//import { StickyContainer, Sticky } from 'react-sticky';
//require('../server/ical-parser.js');
//import Popup from 'reactjs-popup'

import IndexLayout from '../layouts'


//var Scroll = require('react-scroll');
//var Element = Scroll.Element;
//var scroller = Scroll.scroller;


import styled from '@emotion/styled'




import 'modern-normalize'
import '../styles/normalize'
import Navigation from "../components/Navigation";

import HomeSlider from "../components/HomeSlider";
import {TopDiv} from "../components/index/TopDiv";
import {MidDiv} from "../components/index/MidDiv";
import {BottomDiv} from '../components/index/BottomDiv';

const logo = require('../../assets/images/V7_Logo_1.png')

const CenterDiv = styled.div`
width:100%;
display:flex;
justify-content:center;
align-items:center;
`;
const CenterV = styled.img`
width:30%;
height;auto;


`;

const StyledHeader = styled.header`
align-items: center;
text-align: center;

background-color:white;
opacity:1;
z-index:999999;
padding-top:2%;
  @media screen and (max-width: 1200px) {

display:none;
   }

`


const IndexPage = ( {pathContext: { locale }}) => (



      <IndexLayout locale={locale}>
        <HomeSlider></HomeSlider>

        <StyledHeader>
<CenterDiv>

            <CenterV src={logo}/>
</CenterDiv>

         <Navigation></Navigation>

        </StyledHeader>


        <TopDiv></TopDiv>

        <MidDiv></MidDiv>
        <BottomDiv></BottomDiv>


      </IndexLayout>

    )

export default IndexPage
