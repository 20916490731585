import * as React from 'react'
import styled from '@emotion/styled'
import Typist from 'react-typist';
import { FormattedMessage } from 'react-intl';
import Loader from 'react-loader-spinner'

const StyledSpinner = styled.div`
width:100%;
height:100%;
margin-top:15px;
margin-bottom:15px;
transform: rotate(180deg);
display:flex;
justify-content:center;
align-items:center;

`
const StyledTopDiv = styled.div`
letter-spacing:1px !important;
  width: 80%;
display: flex;
align-items:flex-start;
height:auto;
min-height:240px;
margin-left:auto;
margin-right:auto;
justify-content: space-between;
font-family: "Theano Didot", Regular;
color:rgb(68, 68, 68);
padding-top:4%;

  @media screen and (max-width: 1295px) {
display:block;
padding-top:none;
height:auto;
min-height:auto;

   }


`;

const StyledP = styled.p`
font-family: "Theano Didot", Regular;
font-style: italic;
font-size: 200%;
text-align: right;
line-height: 45px;
letter-spacing: 2px;
color:rgb(68, 68, 68);
width:25%;
height:100%;
position: relative; 


  @media screen and (max-width: 1295px) {
height:auto !important;
text-align:center;
margin-left:auto;
margin-right:auto;
width:100%;

   }


`;

const Einzug = styled.p`
font-family: "Theano Didot", Regular;
font-size: 1.22em;
line-height: 1.5em;
color:rgb(68, 68, 68);
text-indent: 20px;
width:30%;
color:rgb(68, 68, 68);
height:100%;
position: relative; 

  @media screen and (max-width: 1295px) {
height:auto;
width:100%;
margin:auto;
margin-top:20px;
display:none;

   }
`;

const P = styled.p`
font-family: "Theano Didot", Regular;
font-size: 1.22em;
line-height: 1.5em;
color:rgb(68, 68, 68);
width:30%;
color:rgb(68, 68, 68);
height:100%;
position: relative; 

  @media screen and (max-width: 1295px) {
height:auto;
width:100%;
margin:auto;
margin-top:20px;
display:none;


   }
`;


export class TopDiv extends React.Component {

  constructor(props){
    super(props)
    this.state= {
      renderDone:false
    }
  }

  componentDidMount(){
    this.setState({renderDone:true})
  }

  render () {
    return (
  <StyledTopDiv>

<StyledP>
  <FormattedMessage id="home_header_1" />
  <br/>
  <FormattedMessage id="home_header_2" />
  <br/>
  <FormattedMessage id="home_header_3" />
</StyledP>
    <Einzug>  <FormattedMessage id="home_text_1" />
      <br/>
      <FormattedMessage id="home_text_2" />
      <FormattedMessage id="home_text_3" />
    </Einzug>
    <P>      <FormattedMessage id="home_text_6" />  "<FormattedMessage id="home_text_7" />" <FormattedMessage id="home_text_8" />
    </P>



  </StyledTopDiv>

    )
  }
}

